import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import PartnerWithUs from '../components/Layout/PartnerWithUsLayout'

const Partner = () => {
    return (
        <>
            <SEO title="Partner" />
            <Layout withFooter withHeader>
                <Box>
                    <PartnerWithUs />
                </Box>
            </Layout>
        </>
    )
}

export default Partner
